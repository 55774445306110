let _player; 
let player_events = ['loadstart','play', 'pause', 'timeupdate']; 

const EventEmitter = require('eventemitter3');
const e = new EventEmitter();

class PlayerService {
  get player(){
    return _player; 
  }
  set player(player){
    _player = player; 
    for (let index = 0; index < player_events.length; index++) {
      const ev = player_events[index];
      _player.on(ev, () => {
        e.emit(ev); 
      });       
    }
  }
  on(event, fn, context) {
    e.on(event, fn, context);
  }  
} 

export default new PlayerService(); 