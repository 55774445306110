
import playerService from "./PlayerService";

let MAPP_active = {
    title: 'web - live - Live RDS Active - rdsactive.it',
    mg1: 'Live RDS Active - rdsactive.it',
    mg2: 'web',
    mg3: 'live',
    mg4: 'Live RDS Active',
    mg5: '16-04-2024', //Data messa in onda
    mg6: 'RDS ACTIVE',
    property_path: 'rds-italia',
    cg_02: 'web',
    cg_03: 'www.rdsactive.it',
}
let snapshot_diretta = 0; 
let snapshot_onair = 0;

function trimDate() {
    let d = new Date();
    const year = d.getFullYear();
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    let separator = '-';
    return `${day}${separator}${month}${separator}${year}`;
}

class Analytics {
    init() {
        //creare stringa di parametri mg1,mg2...
        MAPP_active.mg5=trimDate();
        let MAPP_mg =  `mg1=${MAPP_active.mg1};mg2=${MAPP_active.mg2};mg3=${MAPP_active.mg3};mg4=${MAPP_active.mg4};mg5=${MAPP_active.mg5};mg6=${MAPP_active.mg6}`
        //invio MAPP Navigation > Pages
        window.webtrekk_page_update();    
        //invio MAPP Navigation > Media
        playerService.on('loadstart', ()=> {             
            let volume = Math.round(playerService.player.volume()*100);                 
            window.wt_sendinfo_media(MAPP_active.title, "init", "0", "0", MAPP_mg , "0", volume, "0");
        }); 
        playerService.on('play', ()=> {  
            let volume = Math.round(playerService.player.volume()*100);  
            window.wt_sendinfo_media(MAPP_active.title, "play", "0", "0", MAPP_mg , "0", volume, "0");
        }); 
        playerService.on('pause', ()=> {       
            let volume = Math.round((playerService.player.volume())*100);     
            window.wt_sendinfo_media(MAPP_active.title, "pause", "0", "0", MAPP_mg , "0", volume, "0");
            // TOBE ora in  wt_mediaTracking.js > sendinfo_media con pause non inviamo mg1,mg2,mg3
            // const videoPlayer = playerService.player;             
            // let volume = Math.round(videoPlayer.volume()*100);   
            // let currentTime = Math.round(videoPlayer.currentTime());
            // window.wt_sendinfo_media(MAPP_active.title, "pause", currentTime, "0", "ck12=n;ck13=n", "0", volume, "0");
        }); 
        playerService.on('timeupdate', () => {
            const videoPlayer = playerService.player; 
            const volume = Math.round(videoPlayer.volume()*100) ;            
  
            if (videoPlayer.currentTime() - snapshot_diretta > 30) {            
                snapshot_diretta = videoPlayer.currentTime() ?? 0;                  
                window.wt_sendinfo_media(MAPP_active.title, "pos", Math.round(videoPlayer.currentTime()), "0", "ck12=n;ck13=n", "0", volume, "0");           
            }
            if (videoPlayer.currentTime() - snapshot_onair > 120) {              
                snapshot_onair = videoPlayer.currentTime() ?? 0;                  
                window.wt_sendinfo_media(MAPP_active.title, "on-air", Math.round(videoPlayer.currentTime()), "0", "ck12=n;ck13=n", "0", volume, "0");           
            }                    
          });
    }
}

const analytics = new Analytics();

export default analytics;