<template>
  <div
    class="rds-player"
    @click="togglePlay"
    :style="{ backgroundImage: `url('${song.cover}')` }"
  >
    <div class="play-button" :class="{ playing }"></div>
    <VjsPlayer ref="videoPlayer" :options="videoOptions" v-if="!loading" />
    <div class="song-meta desktop-only">
      <span class="artist">{{ songArtist }}</span>
      <span> - </span>
      <span class="title">{{ songTitle }}</span>
    </div>
  </div>
</template>

<script>
// import store from 'src/store';
// import 'video.js/dist/video-js.css';
import VjsPlayer from "./VjsPlayer.vue";
import axios from "axios";
//
// function onPlay() {
//   let tracks = this.textTracks();
//   let segmentMetadataTrack;
//   for (let i = 0; i < tracks.length; i++) {
//     if (tracks[i].label === 'Timed Metadata') {
//       segmentMetadataTrack = tracks[i];
//       break;
//     }
//   }
//   if (segmentMetadataTrack) {
//     segmentMetadataTrack.on('cuechange', () => {
//       onCuechange(segmentMetadataTrack);
//     });
//   } else {
//     setTimeout(onPlay.bind(this), 500);
//   }
// }
// async function onCuechange(segmentMetadataTrack) {
//   let activeCue = segmentMetadataTrack.activeCues[0];
//   if (activeCue && activeCue.value.key == 'TXXX') {
//     const meta = JSON.parse(activeCue.value.data);
//     console.log(meta);
//   }
// }
async function getSongMetadata() {
  const data = await axios
    .get("https://media.rds.it/json/rdsactive/current")
    .then((r) => r.data);
  return data.current_song;
}
export default {
  name: "VideoPlayerContainer",
  emits:['metadata'],
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
  },
  components: {
    VjsPlayer,
  },
  computed:{
    songTitle(){
      let title=this.song.title;
      if(title!='SI MUOVE AL RITMO DEL TUO ALLENAMENTO!'){
        title=title.toLowerCase().split(/ /).map(i => i[0].toUpperCase()+i.substring(1)).join(' ');
      }
      return title;
    },
    songArtist(){
      let artist=this.song.artist;
      if(artist!='RDS Active'){
        artist=artist.toLowerCase().split(/ /).map(i => i[0].toUpperCase()+i.substring(1)).join(' ');
      }
      return artist;
    }
  },
  data() {
    return {
      playing: false,
      loading: false,
      player: null,
      videoOptions: {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: this.videoUrl,
            type: "application/x-mpegurl",
          },
        ],
      },
      song: {
        artist: "RDS Active",
        title: "SI MUOVE AL RITMO DEL TUO ALLENAMENTO!",
        cover: "https://media.rds.it/channels/active/rdsactive_cover_placeholder_next.png",
      },
      // store,
    };
  },
  methods: {
    togglePlay() {
      if (!this.playing) {
        this.$refs.videoPlayer.player.play();
      } else {
        this.$refs.videoPlayer.player.pause();
      }
      this.playing = !this.playing;
    },
    async getAndReplaceMeta() {
      const song = await getSongMetadata();
      console.log(song);
      if (JSON.stringify(song) != JSON.stringify(this.song)) {
        this.song = song;
        this.$emit('metadata',song);
      }
    },
  },
  mounted() {
    this.getAndReplaceMeta();
    setInterval(() => {
      this.getAndReplaceMeta();
    }, 30 * 1000);
  },
};
</script>
