<template>
  <div class="flex container">
    <img class="donna-active" src="@/assets/donna.png" />
    <div class="left flex-v">
      <div class="logo">
        <img src="@/assets/logoActive.png" />
        <div class="claim">SI MUOVE AL RITMO DEL TUO ALLENAMENTO!</div>
      </div>
      <div class="app-stores desktop-only flex-v">
        <div class="ios" onclick="window.open('https://itunes.apple.com/it/app/rds/id319667151?mt=8');"></div>
        <div class="android" onclick="window.open('https://play.google.com/store/apps/details?id=com.beeweeb.rds');">
        </div>
      </div>
    </div>
    <div class="right flex">
      <RDSPlayer :videoUrl="STREAM_URL" @metadata="onMetadata" />
    </div>
    <div class="mobile-only">
      <div class="song-meta">
        <span class="artist">{{ songArtist }}</span>
        <span> - </span>
        <span class="title">{{ songTitle }}</span>
      </div>
      <div class="app-stores flex">
        <div class="ios" onclick="window.open('https://itunes.apple.com/it/app/rds/id319667151?mt=8');"></div>
        <div class="android" onclick="window.open('https://play.google.com/store/apps/details?id=com.beeweeb.rds');">
        </div>
      </div>
    </div>
    <div class="iubenda-btn">
      <a href="https://www.iubenda.com/privacy-policy/88611124"
        class="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe" title="Privacy Policy ">
        Privacy Policy</a>
      <a href="https://www.iubenda.com/privacy-policy/88611124/cookie-policy"
        class="iubenda-black no-brand iubenda-noiframe iubenda-embed iubenda-noiframe" title="Cookie Policy ">
        Cookie Policy</a>
    </div>
  </div>
</template>

<script>
import RDSPlayer from "./components/RDSPLayer.vue";
import analytics from "./js/wt_analytics";
const STREAM_URL =
  "https://stream.rds.radio/audio/rdsactive.stream_aac/chunklist.m3u8?type=mobile";
export default {
  name: "App",
  components: {
    RDSPlayer,
  },
  data() {
    return {
      STREAM_URL,
      song: {
        artist: "RDS Active",
        title: "SI MUOVE AL RITMO DEL TUO ALLENAMENTO!",
        cover: "https://media.rds.it/channels/active/rdsactive_cover_placeholder_next.png",
      },
    };
  },
  computed: {
    songTitle() {
      let title = this.song.title;
      if (title != 'SI MUOVE AL RITMO DEL TUO ALLENAMENTO!') {
        title = title.toLowerCase().split(/ /).map(i => i[0].toUpperCase() + i.substring(1)).join(' ');
      }
      return title;
    },
    songArtist() {
      let artist = this.song.artist;
      if (artist != 'RDS Active') {
        artist = artist.toLowerCase().split(/ /).map(i => i[0].toUpperCase() + i.substring(1)).join(' ');
      }
      return artist;
    }
  },
  methods: {
    onMetadata(song) {
      this.song = song;
    }
  },
  created() {
    analytics.init();    
  }
};
</script>

<style lang="scss">
@import "./assets/main.scss";
</style>
