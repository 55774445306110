<template>
  <div>
    <audio ref="videoPlayer" class="video-js vjs-fluid hide" ></audio>
  </div>
</template>

<script>
import videojs from 'video.js';
import playerService from '../js/PlayerService';

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    timecode: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    const vm = this;
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        if (vm.timecode) {
          vm.player.pause();
          vm.player.currentTime(vm.timecode);
          vm.player.play();
        }
        console.log('onPlayerReady', this);
      }
    );
    playerService.player = this.player; 
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>